// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alerts-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.alerts-table th, .alerts-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.alerts-table th {
    background-color: #7b8ae94f;
}

.active {
    background-color: #ffffff;
    color: red;
    text-align: center;
}

.active:hover{
    background-color: rgb(223, 124, 124);
    color: white;
}

.error {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
}
.outside-allerts-wrapper{
    padding: 50px 50px 0 50px;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Allert/allert.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;IACzB,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".alerts-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n}\n\n.alerts-table th, .alerts-table td {\n    padding: 10px;\n    border: 1px solid #ddd;\n    text-align: left;\n}\n\n.alerts-table th {\n    background-color: #7b8ae94f;\n}\n\n.active {\n    background-color: #ffffff;\n    color: red;\n    text-align: center;\n}\n\n.active:hover{\n    background-color: rgb(223, 124, 124);\n    color: white;\n}\n\n.error {\n    color: red;\n    font-weight: bold;\n    margin-bottom: 20px;\n}\n.outside-allerts-wrapper{\n    padding: 50px 50px 0 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
