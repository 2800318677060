// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    margin: 0;
    padding: 0;
}
.common-margin{
    padding: 5% 10% 5% 5%;
}
h1{
    font-size: 32px;
    line-height: 38px;
    font-weight: bold;
    color:#ffffff;
}
h2{
    font-size: 32px;
    line-height: 38px;
    font-weight:700;
    color:#ffffff;
}
h3{
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    color:#ffffff;
}
h5{
    font-size: 18px;
    line-height: 24px;
    color:#000000;
    font-weight: 500;
}
p{
    font-size: 18px;
    line-height: 24px;
    color:#ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Common-Componet/common.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;AACd;AACA;IACI,qBAAqB;AACzB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;AACjB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,aAAa;AACjB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;AACjB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,aAAa;AACjB","sourcesContent":["*{\n    margin: 0;\n    padding: 0;\n}\n.common-margin{\n    padding: 5% 10% 5% 5%;\n}\nh1{\n    font-size: 32px;\n    line-height: 38px;\n    font-weight: bold;\n    color:#ffffff;\n}\nh2{\n    font-size: 32px;\n    line-height: 38px;\n    font-weight:700;\n    color:#ffffff;\n}\nh3{\n    font-size: 24px;\n    line-height: 30px;\n    font-weight: bold;\n    color:#ffffff;\n}\nh5{\n    font-size: 18px;\n    line-height: 24px;\n    color:#000000;\n    font-weight: 500;\n}\np{\n    font-size: 18px;\n    line-height: 24px;\n    color:#ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
