
import './App.css';
import Login from './Component/Login/login';
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import SignUp from './Component/Signup/signup';
import User from './Component/User/user';
import Services from './Component/Services/services';
import Allert from './Component/Allert/allert';
import Dashboard from './Component/Dashboards/dashboard';
import Traces from './Component/Traces/traces';
import Prompts from './Component/Prompts/prompts';
import Documentation from './Component/Documentation/documentation';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login/>}/>
      <Route path="/signup" element={<SignUp/>}/>
      <Route path="/user" element={<User/>}/>
      <Route path="/services" element={<Services/>}/>
      <Route path="/allerts" element={<Allert/>}/>
      <Route path="/dashboard" element={<Dashboard/>}/>
      <Route path="/traces" element={<Traces/>}/>
      <Route path="/chat" element={<Prompts/>}/>
      <Route path="/documentation" element={<Documentation/>}/>
      <Route path="/prompts" element={<Prompts/>}/>
    </Routes>
    </BrowserRouter>  
  );
}

export default App;
