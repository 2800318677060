// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.semicircular-progress-bar {
    overflow: visible;
    width: 100%;
}

.semicircular-progress-bar-bg {
    fill: none;
    stroke: #d6d6d6;
}

.semicircular-progress-bar-fg {
    fill: none;
    transition: stroke-dashoffset 0.35s;
}
.meter-label h3{
    color: black;
    font-weight: 500;
    padding-right: 5px;
}
.meter-label p{
    color: black;
    font-size: small;
}

.meter-label{
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 15px;
    align-items: end;
}
.circular-meters{
    display: flex;
    gap: 15px;
}
.circular-meter{
    display: flex;
    padding: 50px 50px;
    border-radius: 12px;
    box-shadow: 0 8px 8px rgb(0 6 255 / 10%);
    flex-direction: column;
    background: #fcfcfc;
    /* width: 35%; */
    margin-left: 3px;
}

@media (max-width: 768px) {
    .circular-meters {
        margin-bottom: 50px;
        width: -webkit-fill-available;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        width: 105%;
        margin-left: 20px;
        height: 25rem;
        overflow: scroll;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Component/Semicircular/semicircular.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,mCAAmC;AACvC;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,wCAAwC;IACxC,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI;QACI,mBAAmB;QACnB,6BAA6B;QAC7B,aAAa;QACb,6BAA6B;QAC7B,sBAAsB;QACtB,WAAW;QACX,iBAAiB;QACjB,aAAa;QACb,gBAAgB;IACpB;AACJ","sourcesContent":[".semicircular-progress-bar {\n    overflow: visible;\n    width: 100%;\n}\n\n.semicircular-progress-bar-bg {\n    fill: none;\n    stroke: #d6d6d6;\n}\n\n.semicircular-progress-bar-fg {\n    fill: none;\n    transition: stroke-dashoffset 0.35s;\n}\n.meter-label h3{\n    color: black;\n    font-weight: 500;\n    padding-right: 5px;\n}\n.meter-label p{\n    color: black;\n    font-size: small;\n}\n\n.meter-label{\n    justify-content: center;\n    display: flex;\n    align-items: center;\n    margin-top: 15px;\n    align-items: end;\n}\n.circular-meters{\n    display: flex;\n    gap: 15px;\n}\n.circular-meter{\n    display: flex;\n    padding: 50px 50px;\n    border-radius: 12px;\n    box-shadow: 0 8px 8px rgb(0 6 255 / 10%);\n    flex-direction: column;\n    background: #fcfcfc;\n    /* width: 35%; */\n    margin-left: 3px;\n}\n\n@media (max-width: 768px) {\n    .circular-meters {\n        margin-bottom: 50px;\n        width: -webkit-fill-available;\n        display: flex;\n        justify-content: space-around;\n        flex-direction: column;\n        width: 105%;\n        margin-left: 20px;\n        height: 25rem;\n        overflow: scroll;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
