// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-wrapper {
    display: flex;
    gap: 20px;
}

.chart-container {
    /* display: flex;
    flex-direction: column; */
    height: 303px;
    width: 470px;
    margin-top: 20px;
    background-color: #dee3ff7d;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 5px rgb(0 6 255 / 10%);
}

.chart-container h3 {
    margin-bottom: 20px;
    color: black;
    text-align: center;
}

.error {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
}
.user-inner-wrapper {
    /* padding: 2.5rem 6rem 4rem 6rem; */
    height: calc(100vh - 17.1vh);
    overflow-y: auto;
}
.user-dashboard a{
    padding: 0;
}
.outside-dashboard-wrapper{
    padding: 50px 50px 0px 50px;
}`, "",{"version":3,"sources":["webpack://./src/Component/Dashboards/dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI;6BACyB;IACzB,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,oCAAoC;IACpC,4BAA4B;IAC5B,gBAAgB;AACpB;AACA;IACI,UAAU;AACd;AACA;IACI,2BAA2B;AAC/B","sourcesContent":[".dashboard-wrapper {\n    display: flex;\n    gap: 20px;\n}\n\n.chart-container {\n    /* display: flex;\n    flex-direction: column; */\n    height: 303px;\n    width: 470px;\n    margin-top: 20px;\n    background-color: #dee3ff7d;\n    padding: 20px;\n    border-radius: 10px;\n    box-shadow: 0 5px 5px rgb(0 6 255 / 10%);\n}\n\n.chart-container h3 {\n    margin-bottom: 20px;\n    color: black;\n    text-align: center;\n}\n\n.error {\n    color: red;\n    font-weight: bold;\n    margin-bottom: 20px;\n}\n.user-inner-wrapper {\n    /* padding: 2.5rem 6rem 4rem 6rem; */\n    height: calc(100vh - 17.1vh);\n    overflow-y: auto;\n}\n.user-dashboard a{\n    padding: 0;\n}\n.outside-dashboard-wrapper{\n    padding: 50px 50px 0px 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
