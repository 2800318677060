// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.targets-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.targets-table th,
.targets-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.targets-table th {
    background-color: #7b8ae94f;
}

.targets-table tr:hover {
    background-color: #f9f9f9;
}

.healthy {
    color: green;
}

.unhealthy {
    color: red;
}

.label {
    display: inline-block;
    padding: 2px 6px;
    margin-right: 5px;
    border-radius: 4px;
}

.error {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
}

td a {
    color: black;
}

td h3 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    color: black;
}

.outer-service-wrapper {
    padding: 50px 50px 0px 50px;
}

.user-header span {
    padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Component/Services/services.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;;IAEI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".targets-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n}\n\n.targets-table th,\n.targets-table td {\n    padding: 10px;\n    border: 1px solid #ddd;\n    text-align: left;\n}\n\n.targets-table th {\n    background-color: #7b8ae94f;\n}\n\n.targets-table tr:hover {\n    background-color: #f9f9f9;\n}\n\n.healthy {\n    color: green;\n}\n\n.unhealthy {\n    color: red;\n}\n\n.label {\n    display: inline-block;\n    padding: 2px 6px;\n    margin-right: 5px;\n    border-radius: 4px;\n}\n\n.error {\n    color: red;\n    font-weight: bold;\n    margin-bottom: 20px;\n}\n\ntd a {\n    color: black;\n}\n\ntd h3 {\n    font-size: 20px;\n    line-height: 26px;\n    font-weight: 400;\n    color: black;\n}\n\n.outer-service-wrapper {\n    padding: 50px 50px 0px 50px;\n}\n\n.user-header span {\n    padding-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
